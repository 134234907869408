<template>
  <div>
    <v-navigation-drawer
        :clipped="$vuetify.breakpoint.lgAndUp"
        app
    >
      <v-row class="grey lighten-4">
        <v-col class="pa-10">
          <v-img src="../assets/Logo-Creativ.png"></v-img>
        </v-col>
      </v-row>
      <v-list shaped>
        <div v-for="item in items" :key="item.text" @click="selectItem(item)">
          <v-list-item
              :key="item.text"
              link
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div v-if="$store.getters['GET_IS_AUTHENTICATED']() && $store.getters['GET_ROLES']().find((role) => { return role === 'ROLE_ADMIN' })">
          <v-divider inset></v-divider>
          <v-list-item
              @click="selectItem({ path: '/Accounts' })"
              link
          >
            <v-list-item-action>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-t="'General.UsersManagement'">
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import EventBus from '../bus.js'

export default {
  name: 'SideLeft',

  data () {
    return {
      eventBus: EventBus,
      drawerLeft: true,
      items: [],
      isAuthenticated: false
    }
  },
  created () {
    this.initializeItems()
    EventBus.$on('showLeftNav', () => {
      this.drawerLeft = true
    })
    EventBus.$on('hideLeftNav', () => {
      this.drawerLeft = false
    })
    EventBus.$on('reperesOff', () => {
      this.checked = false
    })
    if (this.$route.path === '/') {
      this.$router.push({ name: 'Sites' })
    }
  },
  mounted () {
    this.isAuthenticated = this.$store.getters['GET_IS_AUTHENTICATED']()
  },
  methods: {
    initializeItems () {
        this.items = [
          { icon: 'mdi-web', text: this.$t('General.Sites'), path: '/Sites' },
          { icon: 'mdi-cup', text: this.$t('General.Products'), path: '/Products' },
          { icon: 'mdi-altimeter', text: this.$t('General.Measures'), path: '/Measures' },
          { icon: 'mdi-texture', text: this.$t('General.Materials'), path: '/Materials' },
          { icon: 'mdi-shape', text: this.$t('General.Categories'), path: '/Categories' },
          { icon: 'mdi-checkerboard', text: this.$t('General.Patterns'), path: '/Decos' },
          { icon: 'mdi-view-dashboard', text: this.$t('General.Sections'), path: '/Sections' },
          { icon: 'mdi-format-font', text: this.$t('General.Texts'), path: '/Texts' },
          { icon: 'mdi-image-edit', text: this.$t('General.Themes'), path: '/Themes' },
          { icon: 'mdi-file-account-outline', text: this.$t('Printfiles.Printfiles'), path: '/Printfiles' },
          { icon: 'mdi-file-account', text: this.$t('General.Orders'), path: '/Orders' }
        ]
      },
      selectItem (item) {
        if (item.path && this.$route.path !== item.path) {
          this.$router.push(item.path)
        }
      }
  }
}
</script>
